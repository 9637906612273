import React, { Fragment, FunctionComponent } from 'react'
import { NavigatorProps } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorProps'
import { useProvinceNavigator } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/ProvinceNavigator/useProvinceNavigator'
import { Box } from '@wh-components/core/Box/Box'
import { ResetAllButton } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/ResetAllButton/ResetAllButton'
import { getStateList } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/ProvinceNavigator/stateList'
import { Checkbox } from '@wh-components/core/FormElements/Checkbox/Checkbox'
import { PossibleLabelContent } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorValue'
import { css } from 'styled-components'
import { Button } from '@wh-components/core/Button/Button'
import { verticals } from '@wh/common/chapter/types/verticals'
import { PossibleNavigatorValue } from '@bbx/search-journey/common/Navigators'
import { getFlatPossibleValues } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'

interface ProvinceNavigatorModalContentProps {
    vertical: verticals
}

export const ProvinceNavigatorModalContent: FunctionComponent<
    Pick<NavigatorProps, 'navigator'> &
        Pick<ReturnType<typeof useProvinceNavigator>, 'handleChange' | 'checkboxState' | 'setCheckboxState' | 'onSubmitMultiSelect'> &
        ProvinceNavigatorModalContentProps
> = ({ navigator, handleChange, setCheckboxState, checkboxState, onSubmitMultiSelect, vertical }) => {
    const stateList = getStateList(vertical)

    const onReset = () => {
        onSubmitMultiSelect(undefined, { stateOverride: {}, keepModalOpen: true })
    }

    return (
        <Fragment>
            {navigator.selectedValues.length >= 1 && (
                <Box
                    height={{ phone: 48, tablet: 27 }}
                    display="flex"
                    alignItems="center"
                    position="absolute"
                    top={{ phone: 0, tablet: 'l' }}
                    right={{ phone: 56, tablet: 32 }}
                >
                    <ResetAllButton navigatorId={navigator.id} onClick={onReset} contextLink={navigator.resetAllInformation?.resetAllUrl} />
                </Box>
            )}
            <form onSubmit={onSubmitMultiSelect}>
                {stateList.map((state, i) => {
                    const hits = getHits(getFlatPossibleValues(navigator), state.name)

                    return (
                        <Fragment key={state.areaId}>
                            <Checkbox
                                label={
                                    <Box paddingLeft={2}>
                                        <PossibleLabelContent label={state.name} showHitsLabel={true} hits={hits} disabled={false} />
                                    </Box>
                                }
                                marginBottom={{ phone: 'xs', tablet: 0 }}
                                id={`${navigator.id}-checkbox-modal-${state.areaId}`}
                                testId={`navigator-${navigator.id}-${state.name}-checkbox`}
                                checked={checkboxState[state.areaId] || false}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        handleChange(state.areaId.toString(), e.target.checked)
                                    } else {
                                        const { [state.areaId.toString()]: _, ...newState } = checkboxState
                                        state.districtList.forEach((district) => {
                                            delete newState[district.areaId]
                                        })
                                        setCheckboxState({ ...newState })
                                    }
                                }}
                                marginTop={i === stateList.length - 1 ? 'm' : undefined}
                                css={css`
                                    break-inside: avoid;
                                `}
                            />
                            <Box
                                css={css`
                                    overflow: hidden;
                                    max-height: ${checkboxState[state.areaId] ? '100%' : 0};
                                `}
                            >
                                {state.districtList.map((district) => {
                                    const districtHits = getHits(getFlatPossibleValues(navigator), district.name)

                                    return (
                                        <Checkbox
                                            key={district.areaId}
                                            label={
                                                <Box paddingLeft={2}>
                                                    <PossibleLabelContent
                                                        label={district.name}
                                                        showHitsLabel={true}
                                                        hits={districtHits}
                                                        disabled={false}
                                                    />
                                                </Box>
                                            }
                                            marginLeft="l"
                                            disabled={!checkboxState[state.areaId]}
                                            marginBottom={{ phone: 'xs', tablet: 0 }}
                                            id={`${navigator.id}-checkbox-modal-${district.areaId}`}
                                            testId={`navigator-${navigator.id}-${district.name}-checkbox`}
                                            checked={checkboxState[district.areaId] || false}
                                            onChange={(e) => handleChange(district.areaId.toString(), e.target.checked)}
                                            css={css`
                                                break-inside: avoid;
                                            `}
                                        />
                                    )
                                })}
                            </Box>
                        </Fragment>
                    )
                })}
                <Button type="submit" display="none">
                    Fertig
                </Button>
            </form>
        </Fragment>
    )
}

const getHits = (possibleValues: PossibleNavigatorValue[], name: string) => {
    return possibleValues?.filter((value) => name === value.label)?.[0]?.hits ?? 0
}
